import React, {
  FC,
  Fragment,
  ReactNode,
  useId,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Action,
  ActionStackProps,
} from './ActionStack.types'
import { usePermissions } from '@/utils/hooks/usePermission'
import clsx from 'clsx'
import { confirmModal } from '@/features/components/modals/confirm'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { match } from 'ts-pattern'
import { Tooltip } from '@/components'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const ActionStack: FC<ActionStackProps> = ({
  actions,
}) => {
  const { hasPermission } = usePermissions()
  const { t } = useTranslation('utils')
  const { t: absoluteT } = useTranslation()

  const handleClick = async (action: Action) => {
    const { confirm, onClick } = action

    if (typeof onClick !== 'function') return

    if (confirm) {
      try {
        await confirmModal({
          type: 'warning',
          message: t(confirm.message),
        })

        onClick()

        return
      } catch (error) {
        // empty block
        return
      }
    }

    onClick()
  }

  const renderIcon = (
    action: Action | null
  ): ReactNode => {
    if (!action) return null

    const {
      onClick,
      icon,
      buttonClassName,
      permission,
      condition,
      tooltip,
    } = action

    if (
      (condition === undefined || condition) &&
      (permission === undefined ||
        hasPermission(permission))
    ) {
      return match(typeof onClick)
        .with('function', () => {
          return tooltip ? (
            <Tooltip
              as={'button'}
              onClick={() => handleClick(action)}
              content={absoluteT(tooltip)}
              place={'bottom'}
              id={useId()}
            >
              <div
                className={clsx(
                  'w-6 h-6 rounded flex items-center justify-center',
                  buttonClassName
                )}
              >
                {React.isValidElement(icon) ? (
                  <Fragment>{icon}</Fragment>
                ) : (
                  <FontAwesomeIcon
                    icon={icon as IconDefinition}
                    className={'text-white'}
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <button
              className={clsx(
                'w-6 h-6 rounded flex items-center justify-center',
                buttonClassName
              )}
              onClick={() => handleClick(action)}
            >
              {React.isValidElement(icon) ? (
                <Fragment>{icon}</Fragment>
              ) : (
                <FontAwesomeIcon
                  icon={icon as IconDefinition}
                  className={'text-white'}
                />
              )}
            </button>
          )
        })
        .with('string', () => {
          return tooltip ? (
            <Tooltip
              as={Link}
              to={onClick as string}
              content={absoluteT(tooltip)}
              place={'bottom'}
              id={useId()}
            >
              <div
                className={clsx(
                  'w-6 h-6 rounded flex items-center justify-center',
                  buttonClassName
                )}
              >
                {React.isValidElement(icon) ? (
                  <Fragment>{icon}</Fragment>
                ) : (
                  <FontAwesomeIcon
                    icon={icon as IconDefinition}
                    className={'text-white'}
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <Link
              to={onClick as string}
              className={clsx(
                'w-6 h-6 rounded flex items-center justify-center',
                buttonClassName
              )}
            >
              {React.isValidElement(icon) ? (
                <Fragment>{icon}</Fragment>
              ) : (
                <FontAwesomeIcon
                  icon={icon as IconDefinition}
                  className={'text-white'}
                />
              )}
            </Link>
          )
        })
        .otherwise(() => null)
    }

    return <Fragment></Fragment>
  }

  return (
    <div
      className={clsx('flex items-center gap-2')}
    >
      {actions.map((action, index) => (
        <Fragment key={index}>
          {action ? renderIcon(action) : null}
        </Fragment>
      ))}
    </div>
  )
}

export { ActionStack }
