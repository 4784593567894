import igritAPI from '@/utils/api/igrit'
import {
  GetPaymentsResponseInterface,
  GetPaymentStatisticsResponseInterface,
} from '@/features/payments/redux/types'
import { setPaginatedCache } from '@/utils/api/rtkHelper'

export const {
  useGetPaymentsQuery,
  useGetPaymentStatisticsQuery,
  useLazyGetPaymentStatisticsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<
      GetPaymentsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/payments?${query}`,
      transformResponse: (
        response: GetPaymentsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Payment', result),
    }),
    getPaymentStatistics: builder.query<
      GetPaymentStatisticsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/payments/statistics?${query}`,
      transformResponse: (
        response: GetPaymentStatisticsResponseInterface
      ) => response.data,
      providesTags: ['PaymentStatistic'],
    }),
  }),
})
