import { useTranslation } from 'react-i18next'
import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  AccountingReportInterface,
  useDeleteAccountingReportMutation,
  useGetAccountingReportsQuery,
  useMarkAsSentAccountingReportMutation,
  useRefreshAccountingReportMutation,
} from '@/features/accountingReports'
import {
  Query,
  SortingState,
} from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import Carbon from '@/utils/carbon'
import {
  faCheck,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import { ReportTypeEnum } from '@/features/accountingReports/redux/enums'

const Table: FC<
  { baseQuery: Query } & VisibleColumnsInterface
> = ({
  baseQuery,
  visibility,
  setAvailableColumns,
}) => {
  const { t } = useTranslation([
    'form',
    'utils',
    'accounting_reports',
  ])
  const [data, setData] = useState<
    AccountingReportInterface[]
  >([])
  const [sorting, setSorting] =
    useState<SortingState>([])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .page(pagination.pageIndex)
        .sortBySortState(sorting)
        .includes(
          'creator',
          'sender',
          'refresher'
        ),
    [pagination.pageIndex, baseQuery, sorting]
  )
  const { data: apiData } =
    useGetAccountingReportsQuery(query.url())
  const [deleteReport] =
    useDeleteAccountingReportMutation()
  const [refreshReport] =
    useRefreshAccountingReportMutation()
  const [sendReport] =
    useMarkAsSentAccountingReportMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteReport(id).unwrap()
      toast.success(
        t('accounting_reports:list.deleted')
      )
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const handleRefresh = async (id: number) => {
    try {
      await refreshReport(id).unwrap()
      toast.success(
        t('accounting_reports:list.refreshed')
      )
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const handleSend = async (id: number) => {
    try {
      await sendReport(id).unwrap()
      toast.success(
        t('accounting_reports:list.sent')
      )
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const table = useReactTable({
    columns: useMemo(
      () =>
        columns(
          t,
          handleDelete,
          handleRefresh,
          handleSend
        ),
      [t, handleDelete, handleRefresh, handleSend]
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    enableHiding: true,
    manualPagination: true,
    state: {
      pagination,
      sorting,
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
      setPagination={setPagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<AccountingReportInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onRefresh: (id: number) => void,
  onSend: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    enableHiding: true,
    cell: ({ row }) => (
      <span>
        {t(
          `accounting_reports:types.${row.original.type}`
        )}
      </span>
    ),
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.generated_date'),
    enableSorting: true,
    enableHiding: true,
    cell: ({ row }) => (
      <div className={'flex flex-col gap-0.5'}>
        <span>
          {new Carbon(
            row.original.created_at
          ).toDateTimeLocal()}
        </span>
        <span>{row.original.creator?.email}</span>
      </div>
    ),
  }),
  columnBuilder.accessor('refreshed_at', {
    id: 'refreshed_at',
    header: t('form:labels.refreshed_at'),
    enableSorting: true,
    cell: ({ row }) =>
      row.original.refreshed_at ? (
        <div className={'flex flex-col gap-0.5'}>
          <span>
            {new Carbon(
              row.original.refreshed_at
            ).toDateTimeLocal()}
          </span>
          <span>
            {row.original.refresher?.email}
          </span>
        </div>
      ) : (
        <span>
          {t('form:labels.not_refreshed')}
        </span>
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('sent_at', {
    id: 'sent_at',
    header: t('form:labels.sent_at'),
    enableSorting: true,
    cell: ({ row }) =>
      row.original.sent_at ? (
        <div className={'flex flex-col gap-0.5'}>
          <span>
            {new Carbon(
              row.original.sent_at
            ).toDateTimeLocal()}
          </span>
          <span>
            {row.original.sender?.email}
          </span>
        </div>
      ) : (
        <span>{t('form:labels.not_sent')}</span>
      ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.ACCOUNTING_REPORT_DESTROY,
              show: row.original.sent_at === null,
            },
          }),
          {
            icon: faRefresh,
            permission:
              PermissionEnum.ACCOUNTING_REPORT_SAVE,
            onClick: () =>
              onRefresh(row.original.id),
            buttonClassName: 'bg-blue-400',
            tooltip:
              'accounting_reports:list.tooltips.refresh',
            condition:
              row.original.sent_at === null,
          },
          {
            icon: (
              <span
                className={
                  'text-white text-[9px]'
                }
              >
                CSV
              </span>
            ),
            permission:
              PermissionEnum.ACCOUNTING_REPORT_SHOW,
            onClick:
              row.original.csv_download ?? '',
            buttonClassName: 'bg-blue-500',
            tooltip:
              'accounting_reports:list.tooltips.csv',
          },
          row.original?.type ===
          ReportTypeEnum.INVOICE_ACCOUNTING_REPORT
            ? {
                icon: (
                  <span
                    className={
                      'text-white text-[9px]'
                    }
                  >
                    EPP
                  </span>
                ),
                permission:
                  PermissionEnum.ACCOUNTING_REPORT_SHOW,
                onClick:
                  row.original.epp_download ?? '',
                buttonClassName: 'bg-blue-500',
                tooltip:
                  'accounting_reports:list.tooltips.epp',
              }
            : null,
          {
            icon: faCheck,
            permission:
              PermissionEnum.ACCOUNTING_REPORT_SAVE,
            onClick: () =>
              onSend(row.original.id),
            buttonClassName: 'bg-green-500',
            tooltip:
              'accounting_reports:list.tooltips.sent',
            condition:
              row.original.sent_at === null,
          },
        ]}
      />
    ),
  }),
]

export { Table }
