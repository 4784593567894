import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _, { isEmpty } from 'lodash'
import { Label } from '@/features/components/inputs/label'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { Input } from '@/features/components/inputs/input'
import { SearchUser } from '@/components'
import {
  PaymentProviderEnum,
  PaymentStatusEnum,
} from '@/features/payments/redux'

type FilterType = Record<
  string,
  number | string | null | undefined
>

type Props = {
  onSubmit: (
    data: FilterType,
    resetPagination: boolean
  ) => void
}

export const PaymentFilter: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const [filters, setFilters] =
    useState<FilterType>({})
  const [resetPagination, setResetPagination] =
    useState(false)

  const handleSetFilters = (
    key: string,
    value: FilterType[keyof FilterType]
  ) => {
    if (isEmpty(value) || _.isNil(value)) {
      setFilters((filters) => {
        const newFilters = { ...filters }

        delete newFilters[key]

        return newFilters
      })
    } else {
      setFilters((filters) => ({
        ...filters,
        [key]: value,
      }))
    }

    setResetPagination(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onSubmit(filters, resetPagination)

      setResetPagination(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [filters, onSubmit, resetPagination])

  return (
    <div className={'grid lg:grid-cols-4 gap-4'}>
      <div className={'flex flex-col'}>
        <Label label={t('form:labels.user')} />
        <SearchUser
          onChange={(option) =>
            handleSetFilters(
              'user_id',
              option?.value
            )
          }
          isMulti={false}
        />
      </div>
      <Select
        label={t('form:labels.status')}
        onChange={(_, value) =>
          handleSetFilters(
            'status',
            value as string
          )
        }
      >
        {_.map(
          PaymentStatusEnum,
          (value, key) => (
            <Option value={value} key={key}>
              {t(
                `utils:payment_statuses.${value}`
              )}
            </Option>
          )
        )}
      </Select>
      <Select
        label={t('form:labels.payment_provider')}
        onChange={(_, value) =>
          handleSetFilters(
            'provider',
            value as string
          )
        }
      >
        {_.map(
          PaymentProviderEnum,
          (value, key) => (
            <Option value={value} key={key}>
              {t(`utils:providers.${value}`)}
            </Option>
          )
        )}
      </Select>
      <Input
        type={'date'}
        label={t('form:labels.payment_date_from')}
        onChange={(e) =>
          handleSetFilters(
            'payment_date_from',
            e.currentTarget.value
          )
        }
        onFocus={(e) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e.target.showPicker()
        }
      />
      <Input
        type={'date'}
        label={t('form:labels.payment_date_to')}
        onChange={(e) =>
          handleSetFilters(
            'payment_date_to',
            e.currentTarget.value
          )
        }
        onFocus={(e) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e.target.showPicker()
        }
      />
      <Input
        type={'number'}
        label={t('form:labels.price_from')}
        onChange={(e) =>
          handleSetFilters(
            'amount_from',
            e.currentTarget.value
          )
        }
      />
      <Input
        type={'number'}
        label={t('form:labels.price_to')}
        onChange={(e) =>
          handleSetFilters(
            'amount_to',
            e.currentTarget.value
          )
        }
      />
    </div>
  )
}
