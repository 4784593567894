import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _, { isEmpty } from 'lodash'
import { Label } from '@/features/components/inputs/label'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { Input } from '@/features/components/inputs/input'
import { SearchUser } from '@/components'

type FilterType = Record<
  string,
  number | string | null | undefined
>

type Props = {
  onSubmit: (
    data: FilterType,
    resetPagination: boolean
  ) => void
}

export const InvoiceFilter: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const [filters, setFilters] =
    useState<FilterType>({})
  const [resetPagination, setResetPagination] =
    useState(false)

  const handleSetFilters = (
    key: string,
    value: FilterType[keyof FilterType]
  ) => {
    if (isEmpty(value) || _.isNil(value)) {
      setFilters((filters) => {
        const newFilters = { ...filters }
        delete newFilters[key]
        return newFilters
      })

      setResetPagination(true)

      return
    } else {
      setFilters((filters) => ({
        ...filters,
        [key]: value,
      }))
    }

    setResetPagination(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onSubmit(filters, resetPagination)

      setResetPagination(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [filters, onSubmit, resetPagination])

  return (
    <div className={'grid lg:grid-cols-4 gap-4'}>
      <div className={'flex flex-col'}>
        <Label label={t('form:labels.user')} />
        <SearchUser
          onChange={(option) =>
            handleSetFilters(
              'user_id',
              option?.value
            )
          }
          isMulti={false}
        />
      </div>
      <Select
        buttonClassNames={'!min-w-full'}
        label={t('form:labels.type')}
        placeholder={t('form:labels.select_type')}
        onChange={(_, value) =>
          handleSetFilters(
            'type',
            value as string
          )
        }
      >
        {_.map(
          ['instant', 'collective'],
          (value, key) => (
            <Option value={value} key={key}>
              {t(`utils:invoice_types.${value}`)}
            </Option>
          )
        )}
      </Select>
      <Input
        type={'date'}
        label={t('form:labels.date_from')}
        onChange={(e) =>
          handleSetFilters(
            'date_from',
            e.currentTarget.value
          )
        }
        onFocus={(e) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e.target.showPicker()
        }
      />
      <Input
        type={'date'}
        label={t('form:labels.date_to')}
        onChange={(e) =>
          handleSetFilters(
            'date_to',
            e.currentTarget.value
          )
        }
        onFocus={(e) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e.target.showPicker()
        }
      />
    </div>
  )
}
