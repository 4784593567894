import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Query,
  SortingState,
} from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  PaymentInterface,
  useGetPaymentsQuery,
} from '@/features/payments/redux'
import Carbon from '@/utils/carbon'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const [data, setData] = useState<
    PaymentInterface[]
  >([])
  const [sorting, setSorting] =
    useState<SortingState>([])
  const query = useMemo(
    () =>
      baseQuery
        .limit(pageSize)
        .page(pageIndex)
        .sortBySortState(sorting)
        .includes('invoice', 'user'),
    [baseQuery, pageIndex, pageSize, sorting]
  )
  const { data: apiData } = useGetPaymentsQuery(
    query.url()
  )

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    getCoreRowModel: getCoreRowModel(),
    data,
    manualSorting: true,
    enableSorting: true,
    enableHiding: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
      setPagination={setPagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<PaymentInterface>()

const columns = (t: TFunction) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('provider', {
    id: 'provider',
    header: t(`form:labels.payment_provider`),
    cell: ({ row }) => (
      <span>
        {t(
          `utils:providers.${row.original.provider}`
        )}
      </span>
    ),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('invoice.signature', {
    id: 'invoice.signature',
    header: t('form:labels.signature'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('user.name', {
    id: 'user.name',
    header: t('form:labels.name'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) => (
      <span>
        {new Carbon(
          row.original.created_at
        ).format('dd.MM.yyyy')}
      </span>
    ),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('payment_date', {
    id: 'payment_date',
    header: t('form:labels.payment_date'),
    enableSorting: true,
    enableHiding: true,
    cell: ({ row }) => {
      if (row.original.payment_date) {
        return (
          <span>
            {new Carbon(
              row.original.payment_date
            ).format('dd.MM.yyyy')}
          </span>
        )
      }

      return <span>-</span>
    },
  }),
  columnBuilder.accessor('amount', {
    id: 'amount',
    header: t('form:labels.price'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) => (
      <span>
        {t(
          `utils:payment_statuses.${row.original.status}`
        )}
      </span>
    ),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },

    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({}),
          row.original.invoice
            ? {
                icon: faFilePdf,
                onClick: row.original.invoice.url,
                buttonClassName: 'bg-red-500',
                tooltip: t(
                  'utils:tooltips.download_pdf'
                ),
              }
            : null,
        ]}
      />
    ),
    enableHiding: true,
  }),
]
