import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { StoreAccountingReportRequestInterface } from '@/features/accountingReports'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { isNil } from 'lodash'
import Carbon from '@/utils/carbon'
import { ReportTypeEnum } from '@/features/accountingReports/redux/enums'

class StoreAccountingReportValidation
  implements
    ValidationInterface<StoreAccountingReportRequestInterface>
{
  defaultValues(): StoreAccountingReportRequestInterface {
    return {
      date_from: '',
      date_to: '',
      type: ReportTypeEnum.INVOICE_ACCOUNTING_REPORT,
    }
  }

  rules(
    t: TFunction
  ): yup.ObjectSchema<
    StoreAccountingReportRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      date_from: yup
        .string()
        .required(t('form:validation.required'))
        .test(
          'is-greater-than',
          function (value) {
            const { date_to } = this.parent

            if (
              isNil(date_to) ||
              isNil(value) ||
              !value.length ||
              !date_to.length
            ) {
              return true
            }

            return new Date(value) <
              new Date(date_to)
              ? true
              : this.createError({
                  message: t(
                    'validation:field_must_be_less_than',
                    {
                      max: new Carbon()
                        .parse(date_to)
                        .format(
                          'dd.MM.yyyy HH:mm'
                        ),
                    }
                  ),
                  path: 'date_from',
                })
          }
        )
        .transform((value) =>
          value.length
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
      date_to: yup
        .string()
        .required(t('validation:required'))
        .test('is-less-than', function (value) {
          const { date_from } = this.parent

          if (
            isNil(date_from) ||
            isNil(value) ||
            !date_from.length ||
            !value.length
          ) {
            return true
          }

          return new Date(value) >
            new Date(date_from)
            ? true
            : this.createError({
                message: t(
                  'validation:field_must_be_greater_than',
                  {
                    min: new Carbon()
                      .parse(date_from)
                      .format('dd.MM.yyyy HH:mm'),
                  }
                ),
                path: 'date_to',
              })
        })
        .transform((value: string) =>
          value.length
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
      type: yup
        .mixed<ReportTypeEnum>()
        .oneOf(Object.values(ReportTypeEnum))
        .required(t('validation:required')),
    })
  }
}

export { StoreAccountingReportValidation }
