import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@/features/components/inputs/input'
import { Query } from '@/utils/query'
import {
  PaymentStatisticsInterface,
  useLazyGetPaymentStatisticsQuery,
} from '@/features/payments/redux'

type Props = {
  query: Query
}

export const PaymentStatistics: React.FC<
  Props
> = ({ query: baseQuery }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'payments',
  ])
  const [getStatistics] =
    useLazyGetPaymentStatisticsQuery()
  const [statistics, setStatistics] =
    useState<PaymentStatisticsInterface>({
      count: 0,
      amount_value: 0,
    })

  // const query = useMemo(
  //   () => baseQuery,
  //   [baseQuery]
  // )
  useEffect(() => {
    getStatistics(baseQuery.url())
      .unwrap()
      .then((response) => {
        setStatistics(response)
      })
  }, [baseQuery])

  return (
    <div
      className={
        'flex flex-col lg:flex-row lg:flex-wrap gap-4'
      }
    >
      <Input
        label={t(
          'form:labels.payment_amounts_value'
        )}
        value={statistics.amount_value}
        readOnly
      />
      <Input
        label={t('form:labels.payments_count')}
        value={statistics.count}
        readOnly
      />
    </div>
  )
}
