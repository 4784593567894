import { useMemo } from 'react'
import { UseSearchType } from './useSearch.type'
import { Query } from '@/utils/query'
import { useSearchParams } from 'react-router-dom'

const useSearch = ({
  query: baseQuery,
  columnsForSearch,
}: UseSearchType) => {
  const [searchParams, setSearchParams] =
    useSearchParams()
  const query = useMemo(() => {
    const query = baseQuery
      ? baseQuery
      : new Query()

    for (const [
      key,
      value,
    ] of searchParams.entries()) {
      if (key === 'search') {
        query.whereSearch(columnsForSearch, value)
      } else {
        query.where(key, value)
      }
    }

    return query
  }, [baseQuery, searchParams])

  const setQueryParams = (
    params: Record<
      string,
      string | number | null | undefined
    >,
    resetPagination = false
  ) => {
    const page = searchParams.get('page')
    const urlParams = new URLSearchParams(
      searchParams
    )

    for (const [key, value] of Object.entries(
      params
    )) {
      if (
        value &&
        ((typeof value === 'string' &&
          value.trim() !== '') ||
          typeof value === 'number')
      ) {
        urlParams.set(key, String(value))
      }
      if (
        typeof value === 'string' &&
        value.trim() === ''
      ) {
        urlParams.delete(key)
      }
    }

    if (page && !resetPagination) {
      urlParams.set('page', page)
    }

    if (resetPagination) {
      urlParams.set('page', '1')
    }

    setSearchParams(urlParams)
  }

  return { query, setQueryParams }
}

export { useSearch }
