import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import React, {
  FC,
  ReactNode,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { StoreAccountingReportValidation } from './rules'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  TextInput,
} from '@/components'
import { Button } from '@/features/components/buttons/button'
import { useStoreAccountingReportMutation } from '@/features/accountingReports'
import { toast } from 'react-toastify'
import { isFetchBaseQueryError } from '@/app/hooks'
import Carbon from '@/utils/carbon'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { ReportTypeEnum } from '@/features/accountingReports/redux/enums'

const Form: FC<InstanceProps<void>> = ({
  isOpen,
  onResolve,
  onReject,
}): ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
    'accounting_reports',
  ])
  const { schema, defaultValues } = useValidation(
    new StoreAccountingReportValidation(),
    t
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof defaultValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const [storeAccountingReport] =
    useStoreAccountingReportMutation()
  const dateFromRef =
    useRef<HTMLInputElement>(null)
  const dateToRef = useRef<HTMLInputElement>(null)

  const onSubmit = async (
    data: typeof defaultValues
  ) => {
    try {
      await storeAccountingReport({
        date_to: new Carbon(data.date_to).format(
          'yyyy-MM-dd'
        ),
        date_from: new Carbon(
          data.date_from
        ).format('yyyy-MM-dd'),
        type: data.type,
      }).unwrap()
      toast.success(
        t('accounting_reports:create.success')
      )
      onResolve()
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(
          t('accounting_reports:create.error')
        )
        onReject()
        return
      }

      toast.error(
        t('utils:errors.something_went_wrong')
      )
      onReject()
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={t(
            'accounting_reports:create.title'
          )}
          onClose={onReject}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={'py-4 flex flex-col gap-4'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  inputRef={dateFromRef}
                  type={'date'}
                  label={t(
                    'form:labels.date_from'
                  )}
                  onFocus={() =>
                    dateFromRef.current?.showPicker()
                  }
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            control={control}
            name={'date_from'}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  inputRef={dateToRef}
                  type={'date'}
                  onFocus={() =>
                    dateToRef.current?.showPicker()
                  }
                  label={t('form:labels.date_to')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            control={control}
            name={'date_to'}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Select
                  label={t('form:labels.type')}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  value={field.value}
                >
                  {_.map(
                    ReportTypeEnum,
                    (value, key) => (
                      <Option
                        value={value}
                        key={key}
                      >
                        {t(
                          `accounting_reports:types.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            control={control}
            name={'type'}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const accountingReportStoreModal =
  create(Form)
